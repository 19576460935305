<template>
  <div>
    Danh sách vé
  </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>
